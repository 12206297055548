import * as React from "react";
import Layout from "../../../components/layout/Layout.component";
import Button from "../../../components/button/Button.component";
import * as style from "./Blackboard.style.module.scss";

const BgGenerator = () => {
  const canvasRef = React.useRef(null);
  const ctxRef = React.useRef(null);
  const lineColor = "#FFFFFF";
  const [selectShape, setselectShape] = React.useState("square");
  const [windowWidth, setwindowWidth] = React.useState("");

  if (typeof window !== "undefined" && windowWidth === "") {
    setwindowWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setwindowWidth(window.innerWidth);
    });
  }

  React.useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;

    const ctx = canvas.getContext("2d");
    ctx.scale(2, 2);
    ctxRef.current = ctx;
    ctx.fillStyle = lineColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }, [windowWidth]);

  const generateRandomNumbers = () => {
    const x = Math.floor(Math.random() * (window.innerWidth - 0 + 1) + 0);
    const y = Math.floor(Math.random() * (window.innerHeight - 0 + 1) + 0);
    const size = Math.floor(Math.random() * (600 - 1 + 1) + 1);
    const hue = Math.floor(Math.random() * (360 - 0 + 1) + 0);
    const saturation = Math.floor(Math.random() * (100 - 0 + 1) + 0);
    const lightness = Math.floor(Math.random() * (80 - 30 + 1) + 30);
    const opacity = Math.random();
    return [x, y, size, hue, saturation, lightness, opacity];
  };

  const generateSquare = () => {
    const [x, y, size, hue, saturation, lightness, opacity] =
      generateRandomNumbers();
    ctxRef.current.fillStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
    ctxRef.current.fillRect(x - size / 2, y - size / 2, size, size);
  };

  const generateCircle = () => {
    const [x, y, size, hue, saturation, lightness, opacity] =
      generateRandomNumbers();
    ctxRef.current.fillStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
    ctxRef.current.beginPath();
    ctxRef.current.arc(x, y, size, 0, 2 * Math.PI, 0);
    ctxRef.current.fill();
    ctxRef.current.closePath();
  };

  const generateTriangle = () => {
    let [x, y, size, hue, saturation, lightness, opacity] =
      generateRandomNumbers();
    size *= 2;
    ctxRef.current.fillStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(x, y);
    ctxRef.current.lineTo(x + size, y);
    ctxRef.current.lineTo(x + size / 2, y - (size * Math.sqrt(3)) / 2);
    ctxRef.current.lineTo(x, y);
    ctxRef.current.fill();
    ctxRef.current.closePath();
  };

  const generateShine = () => {
    const [x, y, size, hue, saturation, lightness, opacity] =
      generateRandomNumbers();
    ctxRef.current.fillStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(x, y);
    ctxRef.current.lineTo(x + size, y + size);
    ctxRef.current.lineTo(0, 0);
    ctxRef.current.fill();
    ctxRef.current.closePath();
  };

  const generateRectangle = () => {
    const numberRectangles = Math.floor(window.innerHeight / 2);
    const hue = Math.floor(Math.random() * (360 - 0 + 1) + 0);
    for (let index = 0; index <= numberRectangles; index += 20) {
      const light = (index / numberRectangles) * 90;
      ctxRef.current.fillStyle = `hsla(${hue}, ${light}%, ${light}%, ${0.3})`;
      ctxRef.current.fillRect(
        index,
        index,
        window.innerWidth - 2 * index,
        window.innerHeight - 2 * index
      );
    }
  };

  const generateImage = () => {
    ctxRef.current.fillStyle = lineColor;
    ctxRef.current.fillRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const iteration = Math.floor(Math.random() * (50 - 10 + 1) + 10);
    let generateShape = null;
    switch (selectShape) {
      case "square":
        generateShape = generateSquare;
        break;
      case "circle":
        generateShape = generateCircle;
        break;
      case "triangle":
        generateShape = generateTriangle;
        break;
      case "shine":
        generateShape = generateShine;
        break;
    }
    if (selectShape !== "rectangle") {
      for (let index = 0; index < iteration; index++) {
        generateShape();
      }
    } else {
      generateRectangle();
    }
  };

  const downloadImage = () => {
    const url = canvasRef.current.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "amazingBackground.png";
    link.href = url;
    link.click();
  };

  return (
    <Layout>
      <div className={style.canvas}>
        <canvas
          ref={canvasRef}
          style={{
            backgroundColor: "#fff",
          }}
        />
        <div className={style.input_values}>
          <select
            name="selectShape"
            id=""
            style={{
              padding: "10px 20px",
              color: "#989898",
              border: "2px solid",
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "capitalize",
              backgroundColor: "#1e1e1e",
            }}
            onChange={({ nativeEvent }) => {
              setselectShape(nativeEvent.target.value);
            }}
          >
            <option value="square">square</option>
            <option value="circle">circle</option>
            <option value="triangle">triangle</option>
            <option value="shine">shine</option>
            <option value="rectangle">rectangle</option>
          </select>
          <Button onClick={generateImage}>Generate</Button>
          <Button onClick={downloadImage}>Download</Button>
        </div>
      </div>
    </Layout>
  );
};

export default BgGenerator;

export function Head() {
  return <title>Background generator</title>;
}
